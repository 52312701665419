import React, { Suspense, lazy, useRef } from 'react';
import { ClientOnly } from 'remix-utils/client-only';
import { useSearchParams } from '@remix-run/react';
import { useLoaderData } from '@remix-run/react';
import { setCSSVariables } from './styles/utils';
import { v4 as uuidv4 } from 'uuid';
import { track } from '~/services/analytics/index.client.ts';
import type { EventName } from '~/utils/analytics.tsx';
import { getPurchaseAnalyticData } from '~/utils/analytics.tsx';
import type {
  AnalyticsPurchaseEventNames,
  RecentStoreValues,
  CleengAnalyticsEvent,
} from '~/services/analytics/events-names';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import webStorefrontManifest from '~/services/plugins/web-storefront/config/manifest.ts';

function addQueryParam(url: string = '/', param: string, value: string) {
  // Create a URL object
  let urlObj = new URL(url, window.location.origin);

  // Get the existing search parameters
  let searchParams = new URLSearchParams(urlObj.search);

  // Add the new query parameter
  searchParams.set(param, value);

  // Set the updated search parameters back to the URL object
  urlObj.search = searchParams.toString();

  // Return the updated URL
  return urlObj.toString();
}

const isValidURL = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};

// @ts-ignore
const Purchase = lazy(() =>
  // @ts-ignore
  import('@cleeng/mediastore-sdk').then((module) => ({
    default: module.Purchase,
  }))
) as React.ComponentType<{
  offerId: string;
  onSuccess: () => Promise<void>;
  couponCode: string | null;
}>;

const Provider = lazy(() =>
  import('react-redux').then((module) => ({ default: module.Provider }))
);

interface CleengProviderProps {
  config: any;
  localizations: any;
}

const getCleengConfig = async () => {
  try {
    // @ts-ignore
    const module = await import('@cleeng/mediastore-sdk');
    const Config = module.Config;
    const Store = module.store;
    return { Config, Store };
  } catch (err) {
    console.error('Failed to load Config', err);
    throw err;
  }
};

export default function CleengProvider({
  config,
  localizations,
}: CleengProviderProps) {
  const [searchParams] = useSearchParams();

  const offerId = searchParams.get('productId');
  const couponCode = searchParams.get('couponCode');

  const [loading, setLoading] = React.useState(true);
  const order = useRef<CleengAnalyticsEvent>({
    detail: {},
    type: undefined,
  });

  const storeRef = React.useRef(null);

  const { authData, successRedirectLink, analyticsTrackData, screen, baseURL } =
    useLoaderData<any>();

  const webStorefrontConfig = getPluginConfig<typeof webStorefrontManifest>(
    webStorefrontManifest,
    config
  );

  const {
    cleeng_publisher_id,
    cleeng_environment,
    paypal,
    paypal_success_url,
    paypal_cancel_url,
    paypal_error_url,
    hidden_payments,
    cleeng_disable_payment_consent_checkbox: disablePaymentConsentCheckbox,
  } = webStorefrontConfig?.general;

  const {
    cleeng_general_font_color,
    cleeng_error_color,
    cleeng_success_color,
    cleeng_primary_color,
    cleeng_loader_color,
  } = webStorefrontConfig?.styles;

  const handleSuccess = async () => {
    handleRedirect();
  };

  const handleRedirect = () => {
    window.location.href =
      addQueryParam(successRedirectLink, 'cb', uuidv4()) || '/';
  };

  const initializeCleeng = React.useCallback(async () => {
    const { Config, Store } = await getCleengConfig();

    storeRef.current = Store;

    setCSSVariables(webStorefrontConfig?.styles);

    Config.setEnvironment(cleeng_environment);
    Config.setPublisher(cleeng_publisher_id);
    Config.setJWT(authData?.access_token);
    Config.setRefreshToken(authData?.refresh_token);
    Config.setTheme({
      fontColor: cleeng_general_font_color,
      successColor: cleeng_success_color,
      primaryColor: cleeng_primary_color,
      loaderColor: cleeng_loader_color,
      errorColor: cleeng_error_color,
    });

    if (disablePaymentConsentCheckbox) {
      Config.setDisablePaymentCheckbox();
    }

    if (!paypal) {
      Config.setHidePayPal();
    }

    if (hidden_payments && hidden_payments.length > 0) {
      const hiddenPayments = String(hidden_payments)
        .split(',')
        .map((payment: string) => Number(payment));

      Config.setHiddenPaymentMethods(hiddenPayments);
    }

    setLoading(false);
  }, [authData, webStorefrontConfig]);

  /**
   * Constructs the PayPal URL for initiating a purchase.
   * The URL includes the event status, redirect URL, and additional parameters
   * for tracking purposes.
   *
   * @param {EventName} eventStatus - The status of the PayPal event (e.g., purchase_success, purchase_failed, purchase_cancelled).
   * @param {string} redirectUrl - The URL to redirect to after the transaction.
   * @param {any} aditionalParams - Additional parameters to include in the URL.
   *
   * @returns {string} The constructed PayPal purchase URL.
   */
  const getPayPalUrl = (
    eventStatus: EventName,
    redirectUrl: string,
    aditionalParams: any
  ): string => {
    return `${baseURL}/paypal-purchase?paypalPurchaseStatus=${eventStatus}&redirectUrl=${encodeURIComponent(
      redirectUrl
    )}&${aditionalParams.toString()}`;
  };

  /**
   * Sets the success, cancel, and error URLs for the PayPal checkout process.
   * This function constructs the URLs based on the provided tracking properties
   * and the current window location. It assigns these URLs to the Cleeng configuration.
   *
   * @param {any} trackProperties - Properties to track during the transaction.
   *
   * @returns {Promise<void>} A promise that resolves when the PayPal URLs are set.
   */
  const setCheckoutPayPalUrls = async (trackProperties: any) => {
    const { Config } = await getCleengConfig();

    const { origin } = new URL(window.location.href);

    const successRedirectUrl = isValidURL(paypal_success_url)
      ? paypal_success_url
      : addQueryParam(successRedirectLink, 'cb', uuidv4())
      ? `${origin}${successRedirectLink}`
      : origin;
    const cancelRedirectUrl = paypal_cancel_url || window.location.href;
    const errorRedirectUrl = paypal_error_url || window.location.href;

    const timestamp = Date.now().toString();
    const params = new URLSearchParams();
    params.append('trackProperties', JSON.stringify(trackProperties));
    params.append('timestamp', timestamp);

    const successUrl = getPayPalUrl(
      'purchase_success',
      successRedirectUrl,
      params
    );
    const cancelUrl = getPayPalUrl(
      'purchase_cancelled',
      cancelRedirectUrl,
      params
    );
    const errorUrl = getPayPalUrl('purchase_failed', errorRedirectUrl, params);

    Config.setCheckoutPayPalUrls({
      successUrl,
      cancelUrl,
      errorUrl,
    });
  };

  /**
   * Handles the analytics event by processing the event type, gathering event data,
   * and tracking the event. This function utilizes the getRecentStoreValues function to
   * retrieve the latest store values based on the event type. It constructs properties for
   * tracking and invokes the appropriate tracking function.
   *
   * @param eventType - The type of purchase event (e.g., 'purchase_triggered', 'purchase', etc.)
   * @param evt - The CleengAnalyticsEvent containing detailed information about the event.
   * @param callback - An optional callback function to be executed after tracking the event.
   *
   * This function also performs the following actions:
   * - Updates the order reference for 'purchase_triggered' events.
   * - Sets PayPal checkout URLs if PayPal integration is available.
   * - Tracks the event with the constructed event name and properties.
   */
  const handleEvent = React.useCallback(
    (
      eventType: AnalyticsPurchaseEventNames,
      evt: CleengAnalyticsEvent,
      callback?: any
    ) => {
      const eventData = getRecentStoreValues(eventType, evt) || {};

      const { eventName, trackData } = getPurchaseAnalyticData(
        eventType,
        analyticsTrackData,
        eventData
      );

      const trackProperties = {
        ...trackData,
        screen_name: screen?.name,
        event_callback: callback ? callback : null,
      };

      if (eventType === 'purchase_triggered') {
        order.current = evt;

        if (paypal) {
          setCheckoutPayPalUrls(trackProperties);
        }
      }

      track(eventName, trackProperties);
    },
    [analyticsTrackData, screen?.name, paypal]
  );

  const handleAnalytics = () => {
    const onPurchaseLoaded = (evt: any) =>
      handleEvent('purchase_triggered', evt);
    const onPurchaseSuccess = (evt: any) => {
      handleEvent('purchase', evt);
      handleEvent('purchase_success', evt, handleRedirect);
    };
    const onPurchaseFailed = (evt: any) => handleEvent('purchase_failed', evt);

    window.addEventListener('MSSDK:Purchase-loaded', onPurchaseLoaded);
    window.addEventListener('MSSDK:purchase-successful', onPurchaseSuccess);
    window.addEventListener('MSSDK:purchase-failed', onPurchaseFailed);

    return () => {
      window.removeEventListener('MSSDK:Purchase-loaded', onPurchaseLoaded);
      window.removeEventListener(
        'MSSDK:purchase-successful',
        onPurchaseSuccess
      );
      window.removeEventListener('MSSDK:purchase-failed', onPurchaseFailed);
    };
  };

  /**
   * Retrieves the most recent store values based on the event type and event details.
   * This function combines the base offer state from the store with additional details
   * from the event, depending on the type of purchase event that has occurred.
   *
   * @param eventType - The type of purchase event (e.g., 'purchase_triggered', 'purchase_cancelled', etc.)
   * @param event - The CleengAnalyticsEvent containing detailed information about the event.
   * @returns An object containing the most recent store values, which includes the base offer state
   *          and any relevant information from the event details based on the event type.
   *          - For 'purchase_triggered' and 'purchase_cancelled', it merges the order details.
   *          - For 'purchase' and 'purchase_success', it merges payment details and total price.
   *          - For any other event type, it returns the base offer state.
   */
  const getRecentStoreValues = (
    eventType: AnalyticsPurchaseEventNames,
    event: CleengAnalyticsEvent
  ): RecentStoreValues => {
    const baseOfferState = (storeRef?.current as any)?.getState()?.offer;
    switch (eventType) {
      case 'purchase_triggered':
      case 'purchase_cancelled': {
        return {
          ...baseOfferState,
          ...event?.detail?.order,
        };
      }
      case 'purchase':
      case 'purchase_success': {
        return {
          ...baseOfferState,
          ...event?.detail?.payment,
          totalPrice: event?.detail?.payment?.totalAmount,
        };
      }
      default: {
        return baseOfferState;
      }
    }
  };

  React.useEffect(() => {
    const unsubscribe = handleAnalytics();

    initializeCleeng();
    return () => {
      unsubscribe();
      handleEvent('purchase_cancelled', order.current);
    };
  }, []);

  return (
    <ClientOnly fallback={<></>}>
      {() => {
        if (loading) {
          return null;
        }

        return (
          <Suspense fallback={<></>}>
            <Provider
              // @ts-ignore
              store={storeRef.current}
            >
              <div className="storefront-container mx-auto h-screen w-[100%] max-w-[1000px] flex-col lg:flex-row">
                {
                  <Suspense fallback={<></>}>
                    <Purchase
                      offerId={offerId as string}
                      onSuccess={handleSuccess}
                      couponCode={couponCode}
                    />
                  </Suspense>
                }
              </div>
            </Provider>
          </Suspense>
        );
      }}
    </ClientOnly>
  );
}
